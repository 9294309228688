
<template>

   
       <div class="loginform">
           <div class="homeform text-center">
             <img alt="vue logo" src="../../assets/lakotools.png" class="img-fluid">
              <form>
                <div class="form-group">
                  <label>Email :</label>
                  <div class="inputfield text-left">
                    <input type="text" v-model="email"  class="form-control">
                  </div>
                </div>
                <div class="form-group ">
                  <label >Password :</label>
                  <div class="inputfield text-left">
                    <input type="password" v-model="password" class="form-control" id="inputPassword">
                    <v-alert v-if="showPasswordError" color="warning"> Invalid Email and/or Password </v-alert>
                  </div>
                </div>
                <div class="form-group " style="text-align:center;">
                  
                  <div class="pa-5">
                  <v-btn color="accent" rounded x-large @click="checkLogin()">Login </v-btn>
                  </div>
                </div>
              </form>

              <div style="text-align:center; ">
                   <p> Don't have an account?  Send an email to <a href="mailto:pdouglas@lakotool.com">pdouglas@lakotool.com</a></p>
              <div style="text-align:center; ">
                
              </div>
              
           </div> 
       </div>
       
    </div>

</template>
<script>
import { db } from "../db";
import store from '@/store/index'
import {mapActions} from 'vuex'
export default {
     data(){
       return {
         password: "",
         email:"",
         showPasswordError: false
       }
     },
     methods:{
       async checkLogin(){
        this.showPasswordError = false;
         const users = await db.collection('appUsers').where("email" , "==",this.email).get()
        
         if(users.docs.length == 0){
            this.showPasswordError = true
           return false;

         } 
         let checkUser = users.docs[0].data()
        checkUser.id = users.docs[0].id
         if(checkUser.password != btoa(this.password)){
           this.showPasswordError = true
           return false;
         }
         else {
           const company = await db.doc('companies/' + checkUser.companyId).get()
           let companyId = " "
             let companyName = " "
           if(checkUser.role == 'customer'){
               companyId = checkUser.companyId
               companyName = company.data().name
           }
           else{
               companyId = "lako"
               companyName = "Lako Tool"
           }
           const payload = {
             role: checkUser.role,
             email: this.email,
             name: checkUser.name,
             company: companyId,
             companyName: companyName,
             repId: checkUser.repId,
             repName: checkUser.repName,
             region: checkUser.region,
             id: checkUser.id
           }
          
           store.dispatch("axSetLogin", payload)
           switch(payload.role){
             case "customer":
               this.$router.push("/customer/home");
               break;
            case "lako":
               this.$router.push("/rep/home");
               break;
            case "admin":
               this.$router.push("/admin/home");
               break;
                  
           }
        
         }
         


       }
     }
}
</script>
<style scoped>
.loginform {
    padding: 20px 200px;
}

.form-group label {
    float: left;
    text-align: right;
    width: 35%;
}

.inputfield input {
    width: 35%;
    margin-left: 3%;
    margin-bottom: 22px;
    border: 1px solid;
    padding: 0 0 0 6px;
}



.buttonsubmit input {
    border: 2px solid black;
    width: 35%;
    box-shadow: 2px 2px;
    margin-left: 38%;
    margin-bottom: 30px;
}
img.img-fluid {
    padding: 53px 0px;
}
@media only screen and (max-width: 768px) {

.loginform {
    padding: 5px;
}
.buttonsubmit input {
   width: 50%;
}
.inputfield input {
    width: 50%;
}
}
</style>
